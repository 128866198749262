import { EnvironmentTypes } from '@environments/environment-types.enum';
import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  type: EnvironmentTypes.PROD,
  production: true,
  api: {
    baseUrl: '/api',
    useTranslations: true
  },
  assets: {
    imagePath: '/assets/img/'
  },
  errorPages: {
    debug: false
  },
  logger: {
    level: NgxLoggerLevel.ERROR
  },
  dealer: {
    oAuth2: {
      encryptionKey: '27@M99cDuSpSfNr&qBlXIGtkY!Zc:I79'
    }
  },
  cookiebot: {
    id: '8cc0ea74-263a-441c-858f-bd3be133ff5e'
  },
  showOrderPeriodStartedPopup: false,
  showJoinPartyPage: false,
  hideSalutationCompany: true,
  salutationUnknownAsDefault: true
};
